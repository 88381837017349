import { useCallback, useMemo, useState } from "react";
import WelcomeUser from "../components/header/WelcomeUser";
import MonthlyReportForm from "../containers/MonthlyReportForm";
import { YearMonth } from "../interfaces";
import {
  getThemeColor,
  getCurrentMonth,
  getCurrentYear,
  getLastDayOfMonth,
} from "../utility";
import {
  ComponentStatsChart,
  IncomeLiteChart,
  KakeiboMonthlyChart,
  MonthlyTransactions,
  TotalBalance,
} from "../data";
import { useGetExpenseByComponentQuery } from "../services/statisticsApi";
import { useGetIncomeLiteTransactionsQuery } from "../services/transactionsApi";

function MonthlyReport() {
  const [yearMonth, setYearMonth] = useState<YearMonth>({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });

  // Memoize the callback to avoid unnecessary re-renders
  const handleYearMonthChange = useCallback((updatedYearMonth: YearMonth) => {
    setYearMonth(updatedYearMonth);
  }, []);

  const endDate = useMemo(
    () => getLastDayOfMonth(yearMonth.year, yearMonth.month),
    [yearMonth]
  );

  const { data: expenseStat, isFetching: expenseLoading } =
    useGetExpenseByComponentQuery(yearMonth);

  const { data: incomeLite, isFetching: incomeLiteLoading } =
    useGetIncomeLiteTransactionsQuery(yearMonth);

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="report-search-form">
        <MonthlyReportForm onYearMonthChange={handleYearMonthChange} />
      </div>
      <div className="report-expense-chart">
        <ComponentStatsChart
          data={expenseStat?.result}
          isFetching={expenseLoading}
          heading="EXPENSE STATISTICS"
          height="42vh"
          width="35%"
          bkgColor={getThemeColor("--alpha-color")}
        />
        <KakeiboMonthlyChart year={yearMonth.year} month={yearMonth.month} />
        <IncomeLiteChart
          data={incomeLite?.result}
          isFetching={incomeLiteLoading}
          heading="INCOME SOURCES"
          height="42vh"
          width="35%"
          bkgColor={getThemeColor("--alpha-color")}
        />
      </div>
      <div className="report-data-panel">
        <TotalBalance effectiveDate={endDate} />
        <MonthlyTransactions year={yearMonth.year} month={yearMonth.month} />
      </div>
    </div>
  );
}

export default MonthlyReport;
