import { MainLoader } from "../../common";
import { useGetKakeiboTrackerQuery } from "../../services/statisticsApi";
import { Chart } from "primereact/chart";
import { getContrastingColor, convertYearMonthToMonth } from "../../utility";

const KakeiboTrackerChart = () => {
  const { data, isLoading } = useGetKakeiboTrackerQuery();
  const labels = data?.result
    .map((item) => convertYearMonthToMonth(item.YEARMONTH.toString()))
    .slice(0, 12);

  const datasetKeys = Object.keys(data?.result[0] || {}).filter(
    (key) => key !== "YEARMONTH"
  );
  const datasets = datasetKeys.map((key, index) => ({
    label: key,
    data: data?.result.slice(0, 12).map((item) => {
      // Calculate the total of all dynamic values in the current row
      const rowTotal = datasetKeys.reduce((sum, k) => sum + (item[k] ?? 0), 0);

      // Calculate the percentage contribution of the current key's value
      const value = item[key] ?? 0;
      return rowTotal > 0 ? (value / rowTotal) * 100 : 0;
    }),

    backgroundColor: getContrastingColor(index),
    pointRadius: 0,
    fill: false,
    //barThickness: 12,
    barPercentage: 0.9,
    categoryPercentage: 0.6,
  }));

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "KAKEIBO TRACKER",
        font: {
          size: 14,
        },
        padding: {
          bottom: 20,
        },
      },
      legend: {
        position: "left",
      },
    },
    scales: {
      x: {
        /* stacked: true, */
        grid: {
          display: false,
        },
        barPercentage: 0.7,
        categoryPercentage: 0.8,
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
  };

  const kakeiboData = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div
          className="card"
          style={{
            display: "flex",
            alignItems: "center",
            width: "60%",
            backgroundColor: "#fff",
            borderRadius: "20px",
            fontSize: "1rem",
          }}
        >
          <Chart
            type="bar"
            data={kakeiboData}
            options={chartOptions}
            style={{ height: "40vh", width: "100%" }}
          />

          {/*
          <Line
            data={kakeiboData}
            options={chartOptions}
            style={{ height: "40vh", width: "100%" }}
          />
               */}
        </div>
      )}
    </>
  );
};

export default KakeiboTrackerChart;
