import { GenericResponse, IStatement } from "../interfaces";
import financeApi from "./financeApi";

const statementsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountStatements: builder.query<GenericResponse<IStatement[]>, string>({
      query: (accountcode) => ({
        url: "ctequery/accountstatement",
        method: "GET",
        params: { account: accountcode },
      }),
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetAccountStatementsQuery } = statementsApi;
export default statementsApi;
