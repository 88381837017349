import React from "react";
import LoginForm from "../containers/LoginForm";

function LoginPage() {
  return (
    <div className="login-background">
      <div>
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <LoginForm />
    </div>
  );
}

export default LoginPage;
