const getCurrencySymbol = (currencyId: number) => {
  switch (currencyId) {
    case 1:
      return "₹";
    case 2:
      return "RM";
    case 3:
      return "$";
    default:
      return "";
  }
};

const getCurrencySymbolFromCode = (currencyCode: string) => {
  switch (currencyCode) {
    case "INR":
      return "₹";
    case "MYR":
      return "RM";
    case "USD":
      return "$";
    default:
      return "";
  }
};

const getCountryCode = (currencyId: number) => {
  switch (currencyId) {
    case 1:
      return "IN";
    case 2:
      return "MY";
    case 3:
      return "US";
    default:
      return "";
  }
};

export { getCurrencySymbol, getCurrencySymbolFromCode, getCountryCode };
