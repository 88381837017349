import React, { useEffect, useState } from "react";
import { ITransactionLite } from "../../interfaces";
import { Chart } from "primereact/chart";
import { MainLoader } from "../../common";

interface IncomeLiteProps {
  data: ITransactionLite[] | undefined;
  isFetching: boolean;
  heading: string;
  height: string;
  width: string;
  bkgColor: string;
}

const IncomeLiteChart: React.FC<IncomeLiteProps> = React.memo((props) => {
  console.log("Rendering Expense Statistic");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (props.data) {
      const labels = props.data.map((item) => item.component).slice(0, 15);
      const values = props.data.map((item) => item.netamountinr);

      const compChart = {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: props.bkgColor,
            barThickness: 20,
          },
        ],
      };

      const options = {
        indexAxis: "y",
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          title: {
            display: true,
            text: props.heading,
            padding: {
              bottom: 20,
            },
            font: {
              size: 14,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                weight: 500,
              },
              callback: (tickValue: number) => {
                return `${(tickValue / 1000).toFixed(0)}K`;
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {},
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 30,
            bottom: 30,
            left: 20,
          },
        },
      };

      setChartData(compChart);
      setChartOptions(options);
    }
  }, [props]);

  return (
    <>
      {props.isFetching && <MainLoader />}
      {!props.isFetching && (
        <div
          className="card"
          style={{
            width: props.width,
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Chart
            type="bar"
            data={chartData}
            options={chartOptions}
            style={{ height: props.height }}
          />
        </div>
      )}
    </>
  );
});

export default IncomeLiteChart;
