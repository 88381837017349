import { MainLoader } from "../../common";
import { getRandomColor, calcPercentChange } from "../../utility";
import { SmartCardItem } from "../dashboard";
import { useGetKakeiboTrackerQuery } from "../../services/statisticsApi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import { useEffect, useState } from "react";
//import { setKakeiboTracker } from "../../store/slice/kakeiboSlice";

const KakeiboCards = () => {
  const storeData = useSelector(
    (state: RootState) => state.kakeiboStore.kakeiboTracker
  );
  const { data } = useGetKakeiboTrackerQuery(undefined, {
    skip: !!storeData,
  });

  const [isLoading, setIsLoading] = useState(storeData.length === 0);
  useEffect(() => {
    setIsLoading(storeData.length === 0);
  }, [storeData]);

  const currentData = storeData[0] || data?.result[0];
  const prevData = storeData[1] || data?.result[1];
  /*
const dispatch = useDispatch();
  useEffect(() => {
    console.log(data);
    if (data) {
      //dispatch(setKakeiboTracker(data.result.slice(0, 2)));
    }
  }, [data, storeData, dispatch]);
  */

  const iconTypes = [
    "ni ni-world",
    "ni ni-paper-diploma",
    "ni ni-money-coins",
    "ni ni-cart",
  ];

  const smartCardItems = Object.keys(currentData || {})
    .filter((key) => key !== "YEARMONTH")
    .map((key, index) => {
      const percentChange = calcPercentChange(
        currentData?.[key] || 0,
        prevData?.[key] || 0
      );

      return {
        title: key,
        dataPoint: `₹ ${currentData?.[key]?.toFixed(2) || "0"}`,
        iconClass: iconTypes[index % iconTypes.length],
        iconColor: getRandomColor(),
        iconStyle: "icon-style",
        trend: `${percentChange.toFixed(2)}%`,
        trendStyle: percentChange < 0 ? { color: "red" } : { color: "#18c379" },
        trendIcon: percentChange < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      };
    });

  return (
    <div className="smartcard-container">
      {isLoading && <MainLoader />}
      {smartCardItems.map((item, index) => (
        <SmartCardItem key={index} {...item} />
      ))}
    </div>
  );
};

export default KakeiboCards;
