import { ComponentGraph } from "../../interfaces";
import { MainLoader } from "../../common";
import { formatYearMonth } from "../../utility";
import React, { useRef } from "react";
import { Chart, ChartOptions, registerables, TooltipItem } from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(...registerables);

interface ItemStatisticsProps {
  data: ComponentGraph[] | undefined;
  type: string;
  isFetching: boolean;
}

const ItemStatisticsChart: React.FC<ItemStatisticsProps> = (props) => {
  const chartRef = useRef(null);
  const labels = props.data?.map((item) =>
    formatYearMonth(item.YearMonth.toString())
  );

  const rootStyle = getComputedStyle(document.documentElement);
  const chartBorderColor = rootStyle.getPropertyValue("--chart-border-color");

  const chartData =
    props.type === "EXPENSE"
      ? props.data?.map((item) => item.TotalExpense)
      : props.data?.map((item) => item.TotalIncome);

  const reportData = {
    labels: labels,
    datasets: [
      {
        label: "Total Balance",
        data: chartData,
        borderColor: chartBorderColor,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const chartOptions: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        reverse: true,
        title: {
          display: false,
          text: "End-of-Month",
          color: "#FF5733",
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#ced4da",
          display: true,
        },
      },
      y: {
        title: {
          display: false,
          text: "Amount",
        },
        grid: {
          color: "#ced4da",
          display: false,
        },
        ticks: {
          color: "#ced4da",
          display: true,
          callback: (tickValue: string | number) => {
            const value =
              typeof tickValue === "number" ? tickValue : parseFloat(tickValue);
            return value >= 1000 ? `${(value / 1000).toFixed(0)} K` : value;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "ITEM STATISTICS CHART",
        color: "#ced4da",
        font: {
          size: 16,
          weight: 400,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      tooltip: {
        enabled: true,
        intersect: false, // Show tooltip when hovering near the line (not only on the point)
        callbacks: {
          label: (tooltipItem: TooltipItem<"line">) => {
            const value = tooltipItem.raw as number;
            return `${tooltipItem.dataset.label}: ${value.toFixed(2)}`;
          },
          title: (tooltipItems: TooltipItem<"line">[]) => {
            return `Period: ${tooltipItems[0].label}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      {props.isFetching && <MainLoader />}
      {!props.isFetching && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            backgroundColor: "var(--gamma-color)",
            borderRadius: "20px",
            fontSize: "1rem",
          }}
        >
          <Line ref={chartRef} data={reportData} options={chartOptions} />
        </div>
      )}
    </>
  );
};

export default ItemStatisticsChart;
