import React from "react";
import { useGetTotalBalanceByDateQuery } from "../../services/reportsApi";
import { MainLoader } from "../../common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface TotalBalanceProps {
  effectiveDate: string;
}

const TotalBalance: React.FC<TotalBalanceProps> = React.memo(
  ({ effectiveDate }) => {
    console.log("Rendering Total Balance");
    const { data, isLoading } = useGetTotalBalanceByDateQuery(effectiveDate);

    return (
      <>
        {isLoading && <MainLoader />}
        {!isLoading && (
          <div className="card flex-table-w35">
            <DataTable
              value={data?.result || []}
              header="TOTAL BALANCE"
              stripedRows
              showGridlines
              size="small"
              tableStyle={{ minWidth: "35rem" }}
            >
              <Column field="account" header="Account"></Column>
              <Column field="balance" header="Balance" />
              <Column
                field="balanceininr"
                header="Balance (INR)"
                body={(rowData) => `₹ ${rowData.balanceininr}`}
              ></Column>
            </DataTable>
          </div>
        )}
      </>
    );
  }
);

export default TotalBalance;
