import { BalanceChart, GenericResponse, MonthlyReport } from "../interfaces";
import { TotalBalance } from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const reportsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getMonthlyReport: builder.query<GenericResponse<MonthlyReport[]>, void>({
      query: () => ({
        url: "ctequery/monthlyreport",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<MonthlyReport[]>) => {
        const sortedData = response.result.slice(0, 12);
        return {
          ...response,
          result: sortedData,
        };
      },
      providesTags: [Tags.MonthlyReport],
    }),

    getBalanceChart: builder.query<GenericResponse<BalanceChart[]>, void>({
      query: () => ({
        url: "dynamicsql/balancechart",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<BalanceChart[]>) => {
        const modifiedResult = response?.result.map((item, index) => {
          const totalBalance = Object.entries(item).reduce(
            (total, [key, value]) => {
              if (key !== "END_DATE" && typeof value === "number") {
                return total + value;
              }
              return total;
            },
            0
          );
          return {
            ...item,
            sequentialId: index + 1,
            TotalBalance: totalBalance,
          };
        });

        return {
          ...response,
          result: modifiedResult,
        };
      },
    }),

    getTotalBalanceByDate: builder.query<
      GenericResponse<TotalBalance[]>,
      string
    >({
      query: (date) => ({
        url: "ctequery/totalbalance",
        params: { date },
      }),
    }),
  }),
});

export const {
  useGetMonthlyReportQuery,
  useGetBalanceChartQuery,
  useGetTotalBalanceByDateQuery,
} = reportsApi;
export default reportsApi;
