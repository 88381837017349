import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Tags } from "../store/tags";

export const financeApi = createApi({
  reducerPath: "financeApi",
  baseQuery: fetchBaseQuery({
    //baseUrl: "https://api.financemanager.co.in/api",
    baseUrl: process.env.REACT_APP_FM_API_BASE_URL,
  }),
  tagTypes: [Tags.MonthlyReport, Tags.AccountsView],
  endpoints: () => ({}), // Empty endpoints initially
});

export default financeApi;
