import LoginPage from "../layouts/LoginPage";
import Register from "../layouts/Register";
import { HashRouter, Routes, Route } from "react-router-dom";
import { UserLayout, CoreLayout } from "../layouts";
import {
  Dashboard,
  Reports,
  Statements,
  Transfers,
  Statistics,
  AddTransaction,
  EditTransaction,
  EditTransfer,
  MonthlyReport,
} from "../pages";

function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
        </Route>

        <Route element={<CoreLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/balance" element={<Reports />} />
          <Route path="/reports" element={<MonthlyReport />} />
          <Route path="/transactions" element={<AddTransaction />} />
          <Route path="/transactions/edit" element={<EditTransaction />} />
          <Route path="/transfers" element={<Transfers />} />
          <Route path="/transfers/edit" element={<EditTransfer />} />
          <Route path="/statements" element={<Statements />} />
          <Route path="/statistics" element={<Statistics />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default AppRouter;
