import "../styles/templates/background.css";
import "../styles/templates/corelayout.css";
import "../styles/templates/sidebar.css";
import "../styles/templates/sidebaritem.css";
import "../styles/fonts/nucleo-icons.css";
import "../styles/fonts/nucleo-svg.css";

import { Sidebar } from "../components/navigation";
import { hocAuthenticate } from "../components/hoc";
import { Outlet } from "react-router-dom";

function CoreLayout() {
  return (
    <div className="core-layout">
      <div className="dual-background">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default hocAuthenticate(CoreLayout);
