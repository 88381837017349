import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonthlyReport } from "../../interfaces";
import { reportsApi } from "../../services";

interface MonthlyReportState {
  financeOverview: MonthlyReport[];
}

const initialState: MonthlyReportState = {
  financeOverview: [],
};

export const monthlyReportSlice = createSlice({
  name: "monthlyreport",
  initialState: initialState,
  reducers: {
    setMonthlyReport: (state, action: PayloadAction<MonthlyReport[]>) => {
      state.financeOverview = action.payload;
    },
    updateTransaction: (state, action: PayloadAction<MonthlyReport>) => {
      state.financeOverview = state.financeOverview.map((item) => {
        if (item.yearmonth === action.payload.yearmonth) {
          item.netexpense = action.payload.netexpense;
          item.netsavings = action.payload.netsavings;
        }
        return item;
      });
    },
    // sample only
    removeTransaction: (state, action: PayloadAction<MonthlyReport>) => {
      state.financeOverview = state.financeOverview.filter((item) => {
        if (item.yearmonth === action.payload.yearmonth) {
          return null;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportsApi.endpoints.getMonthlyReport.matchFulfilled,
      (state, { payload }) => {
        state.financeOverview = payload.result.slice(0, 2);
      }
    );
    /*
      .addMatcher(
        transactionsApi.endpoints.addTransactionRecord.matchFulfilled,
        (state) => {
          state.financeOverview = [];
        }
      );
      */
  },
});

export const { setMonthlyReport, updateTransaction } =
  monthlyReportSlice.actions;
export const monthlyReportReducer = monthlyReportSlice.reducer;
