import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AccountState {
  accountcodes: string[];
}

const initialAccountState: AccountState = {
  accountcodes: [],
};

export const accountSlice = createSlice({
  name: "accountcode",
  initialState: initialAccountState,
  reducers: {
    setAccountCode: (state, action: PayloadAction<string[]>) => {
      state.accountcodes = action.payload;
    },
  },
});

export const { setAccountCode } = accountSlice.actions;
export const accountCodeReducer = accountSlice.reducer;
