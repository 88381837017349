import RegisterForm from "../containers/RegisterForm";

function Register() {
  return (
    <div className="register-background">
      <RegisterForm />
    </div>
  );
}

export default Register;
