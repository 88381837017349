import React from "react";
import { ITransaction, YearMonth } from "../../interfaces";
import { useGetMonthlyTransactionsQuery } from "../../services/transactionsApi";
import { MainLoader } from "../../common";
import { getCurrencySymbolFromCode } from "../../utility";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const MonthlyTransactions: React.FC<YearMonth> = React.memo((props) => {
  console.log("Monthly Transactions");
  const { data, isLoading } = useGetMonthlyTransactionsQuery(props);

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card flex-table-w62">
          <DataTable
            value={data?.result || []}
            header="TRANSACTIONS"
            stripedRows
            scrollable
            scrollHeight="30vh"
            size="small"
          >
            <Column field="effectivedate" header="Date"></Column>
            <Column field="account" header="Account Code"></Column>
            <Column field="component" header="Component"></Column>
            <Column field="type" header="Type"></Column>
            <Column field="people" header="People"></Column>
            <Column field="description" header="Description"></Column>
            <Column
              field="netamount"
              header="Amount"
              body={(rowData: ITransaction) =>
                `${getCurrencySymbolFromCode(rowData.currencycode)} ${
                  rowData.netamount
                }`
              }
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
});

export default MonthlyTransactions;
