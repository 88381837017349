const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0"); // Ensure two-digit format
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = today.getFullYear();
  return `${year}-${month}-${day}`;
};

const getCurrentMonth = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  return month;
};

const getCurrentMonthInWords = () => {
  return new Date().toLocaleString("default", { month: "long" });
};

const getCurrentYear = () => {
  const today = new Date();
  const year = today.getFullYear();
  return year;
};

const getLastDayOfMonth = (year: number, month: number) => {
  const day = new Date(year, month, 0).getDate();
  const mm = String(month).padStart(2, "0");
  return `${year}-${mm}-${day}`;
};

const getMonths = (): [number, string][] => {
  return [
    [1, "January"],
    [2, "February"],
    [3, "March"],
    [4, "April"],
    [5, "May"],
    [6, "June"],
    [7, "July"],
    [8, "August"],
    [9, "September"],
    [10, "October"],
    [11, "November"],
    [12, "December"],
  ];
};

const getActiveYears = (): string[] => {
  const startYear = 2019;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year.toString());
  }

  return years;
};

const convertYearMonthToMonth = (yearMonth: string | number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = parseInt(yearMonth.toString().substring(4, 6), 10) - 1;
  return months[monthIndex] || "Invalid";
};

const convertDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "2-digit",
  };

  const formattedDate = date.toLocaleDateString("en-US", options);
  // Replace space with apostrophe return formattedDate.replace(" ", "'");
  return formattedDate;
};

const formatYearMonth = (yearMonth: string): string => {
  const year = parseInt(yearMonth.slice(0, 4), 10);
  const month = parseInt(yearMonth.slice(4), 10);

  const date = new Date(year, month - 1);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
};

export {
  getCurrentDate,
  getCurrentMonth,
  getCurrentMonthInWords,
  getCurrentYear,
  getLastDayOfMonth,
  getMonths,
  getActiveYears,
  convertYearMonthToMonth,
  convertDateToMonthYear,
  formatYearMonth,
};
