import { useEffect, useState } from "react";
import {
  useDeleteTransferRecordMutation,
  useGetAllTransfersQuery,
} from "../../services/transfersApi";
import { ApiResponse, IDeleteTransfer, ITransfer } from "../../interfaces";
import { InputText } from "primereact/inputtext";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { MainLoader } from "../../common";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "../../styles/templates/transfer.css";
import { toastNotify } from "../../utility";

function AccountTransfers() {
  const { data, isLoading } = useGetAllTransfersQuery();
  const [transfers, setTransfers] = useState<ITransfer[]>();
  const [deleteTransfer] = useDeleteTransferRecordMutation();

  useEffect(() => {
    if (data) setTransfers(data.result);
  }, [data]);

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    trnDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };
    // @ts-ignore
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="search-box">
        <div>&nbsp;&nbsp;Edit Transfer</div>
        <div>
          <i className="fa fa-search search-icon"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </div>
      </div>
    );
  };

  const handleDeleteTransfer = async (transfer: IDeleteTransfer) => {
    const updatedTransfer = { ...transfer, state: "A" };
    try {
      const response: ApiResponse = await deleteTransfer(updatedTransfer);
      if (
        response.data?.issuccess &&
        parseInt(response.data?.result?.returnvalue) >= 0
      ) {
        setTransfers((prev) => prev?.filter((t) => t.trfid !== transfer.trfid));
        toastNotify("Transfer Record deleted !");
      } else if (response.error) {
        toastNotify(response.error.data.errormessages[0], "error");
      }
    } catch (error) {
      toastNotify("Transfer error", "error");
    }
  };

  const header = renderHeader();

  return (
    <div>
      {isLoading ? (
        <MainLoader />
      ) : (
        <DataTable
          value={transfers}
          header={header}
          paginator
          rows={20}
          rowsPerPageOptions={[15, 20, 30]}
          filters={filters}
          showGridlines
          size="small"
          emptyMessage="No Transfers found."
        >
          <Column field="trfid" header="ID"></Column>
          <Column field="date" header="Date"></Column>
          <Column field="component" header="Component"></Column>
          <Column field="sourceaccount" header="Source"></Column>
          <Column field="targetaccount" header="Destination"></Column>
          <Column field="amount" header="Transfer Amount"></Column>
          <Column field="servicecharge" header="Service Charge"></Column>
          <Column field="exchangerate" header="Xchg Rate"></Column>
          <Column field="exchangeamount" header="Exchange Amount"></Column>
          <Column
            header="Delete"
            style={{ width: "6%" }}
            headerStyle={{ minWidth: "5rem" }}
            bodyStyle={{ textAlign: "center", color: "blue" }}
            body={(rowData: IDeleteTransfer) => {
              return (
                <Button
                  className="transfer-delete-button"
                  onClick={() => {
                    handleDeleteTransfer(rowData);
                  }}
                  icon="fas fa-times"
                  severity="danger"
                />
              );
            }}
          ></Column>
        </DataTable>
      )}
    </div>
  );
}

export default AccountTransfers;
