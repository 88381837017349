import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MainLoader, CountryFlag } from "../../common";
import { getCurrencySymbolFromCode } from "../../utility";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { useGetRecentTransactionsQuery } from "../../services/transactionsApi";
import { IRecentTransaction } from "../../interfaces";

function RecentTransactions() {
  const { data, isLoading } = useGetRecentTransactionsQuery();

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card dashboard-tables">
          <DataTable
            value={data?.result || []}
            header="RECENT TRANSACTIONS"
            stripedRows
            showGridlines
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            scrollable
            scrollHeight="35vh"
          >
            <Column field="sequentialId" header="ID"></Column>
            <Column field="trndate" header="Trans Date"></Column>
            <Column
              field="account"
              header="Account Code"
              body={(rowData: IRecentTransaction) => {
                const countryCode = rowData.currencycode.substring(0, 2);
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CountryFlag countryCode={countryCode} />
                    <span style={{ marginLeft: "8px" }}>{rowData.account}</span>
                  </div>
                );
              }}
            ></Column>
            <Column field="type" header="Type"></Column>
            {/*  <Column field="component" header="Component"></Column> */}
            <Column field="description" header="Description"></Column>
            <Column
              field="netamount"
              header="Net Amount"
              body={(rowData: IRecentTransaction) =>
                `${getCurrencySymbolFromCode(
                  rowData.currencycode
                )} ${rowData.netamount.toLocaleString()}`
              }
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default RecentTransactions;
