import React, { useState } from "react";
import "../styles/forms/login.css";
import "../styles/forms/register.css";
import { SelectBoxField, InputTextField } from "../components/formcontrols";
import { User_Roles, inputHelper, toastNotify } from "../utility";
import { useRegisterUserMutation } from "../services/authApi";
import { ApiResponse } from "../interfaces";
import { useNavigate } from "react-router-dom";
import { MainLoader } from "../common";

function RegisterForm() {
  const [registerUser] = useRegisterUserMutation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    username: "",
    password: "",
    role: "",
    name: "",
  });

  const handleUserInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  const userRoleOptions = [
    { value: `${User_Roles.DEMO}`, label: "Demo User" },
    { value: `${User_Roles.CUSTOMER}`, label: "Customer" },
    { value: `${User_Roles.ADMIN}`, label: "Admin" },
  ];

  const handleRegisterUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response: ApiResponse = await registerUser({
      username: userInput.username,
      password: userInput.password,
      role: userInput.role,
      name: userInput.name,
    });

    if (response.data) {
      toastNotify("Registration successful! Please login to continue");
      navigate("/");
    } else if (response.error) {
      toastNotify(response.error.data.errorMessages[0], "error");
    }

    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form className="login-form" method="POST" onSubmit={handleRegisterUser}>
        <h3>Register Here</h3>
        <InputTextField
          className="login-input"
          label="User Name"
          type="text"
          id="username"
          name="username"
          placeholder="Enter Username"
          value={userInput.username}
          required
          onChange={handleUserInput}
        />
        <InputTextField
          className="login-input"
          label="Full Name"
          type="text"
          id="name"
          name="name"
          value={userInput.name}
          onChange={handleUserInput}
        />
        <InputTextField
          className="login-input"
          label="Password"
          type="password"
          id="password"
          name="password"
          value={userInput.password}
          onChange={handleUserInput}
        />
        <SelectBoxField
          label="User Role"
          name="role"
          id="userrole"
          value={userInput.role}
          options={userRoleOptions}
          onChange={handleUserInput}
        />
        <button className="login-button" type="submit">
          Sign Up
        </button>
      </form>
    </div>
  );
}

export default RegisterForm;
