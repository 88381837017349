import React from "react";
import ReactCountryFlag from "react-country-flag";

interface CountryFlagProps {
  countryCode: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode }) => {
  return (
    <ReactCountryFlag
      countryCode={countryCode}
      svg
      style={{
        width: "20px",
        height: "20px",
      }}
      title={countryCode}
    />
  );
};

export default CountryFlag;
