import { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { jwtDecode } from "jwt-decode";
import { UserModel } from "./interfaces";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "./store/slice/userAuthSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      const { fullName, id, email, role } = jwtDecode<UserModel>(localToken);
      dispatch(setLoggedInUser({ fullName, id, email, role }));
    }
  });

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
