import React from "react";
import { Outlet } from "react-router-dom";
import "../styles/templates/userlayout.css";

const UserLayout: React.FC = () => {
  return (
    <div className="user-layout">
      <Outlet /> {/* Renders either Login or Register */}
    </div>
  );
};

export default UserLayout;
