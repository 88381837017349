import { useState } from "react";
import WelcomeUser from "../components/header/WelcomeUser";
import { SmartCard } from "../components/smartcard";
import { CommonTransfers } from "../data";
import { ICommonTransfer } from "../interfaces";
import TransferForm from "../containers/TransferForm";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux";

function Transfers() {
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );

  const [selectedTransfer, setSelectedTransfer] = useState<ICommonTransfer>({
    sourceaccount: "",
    targetaccount: "",
    component: "",
    description: "",
    amount: 0.0,
    mode: "",
    servicecharge: 0.0,
  });

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={false} />
      </div>
      <div className="transact-form-panel">
        <TransferForm commonTransfer={selectedTransfer} />
      </div>
      <CommonTransfers onClickTransfer={setSelectedTransfer} />
    </div>
  );
}

export default Transfers;
