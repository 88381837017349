import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink
import SidebarItem from "./SidebarItem";
import { Button } from "primereact/button";
import { useTheme } from "../../common/ThemeContext";

const Sidebar: React.FC = () => {
  const menuItems = [
    { text: "Dashboard", iconClass: "ni ni-tv-2", path: "/dashboard" },
    {
      text: "Statistics",
      iconClass: "ni ni-chart-bar-32 calendar-color",
      path: "/statistics",
    },
    {
      text: "Monthly Reports",
      iconClass: "ni ni-calendar-grid-58 app-color",
      path: "/reports",
    },
    {
      text: "Balance",
      iconClass: "fas fa-chart-line config-color",
      path: "/balance",
    },
    {
      text: "Transactions",
      iconClass: "far fa-credit-card transact-color",
      path: "#",
      submenuItems: [
        {
          text: "Add",
          iconClass: "fas fa-folder-plus add-color",
          path: "/transactions",
        },
        {
          text: "Edit",
          iconClass: "far fa-edit update-color",
          path: "/transactions/edit",
        },
      ],
    },
    {
      text: "Transfers",
      iconClass: "ni ni-credit-card credit-color",
      path: "#",
      submenuItems: [
        {
          text: "Add",
          iconClass: "fas fa-folder-plus add-color",
          path: "/transfers",
        },
        {
          text: "Edit",
          iconClass: "far fa-edit update-color",
          path: "/transfers/edit",
        },
      ],
    },
    {
      text: "Statements",
      iconClass: "ni ni-app app-color",
      path: "/statements",
    },
    {
      text: "Configuration",
      iconClass: "ni ni-world-2 config-color",
      path: "/configuration",
    },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState<string>("");
  const handleMenuItemClick = (text: string) => {
    setActiveMenuItem(text);
  };

  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
  const handleMenuToggle = (itemText: string) => {
    setExpandedMenu((prev) => (prev === itemText ? null : itemText));
  };

  const { setTheme } = useTheme();

  return (
    <div className="sidenav">
      <h2>Finance Manager</h2>
      <ul className="sidenav-ul">
        {menuItems.map((item) => (
          <div key={item.text}>
            <NavLink
              to={item.path} // Set the path for navigation
              key={item.text}
              className={"sidenav-item"}
              onClick={() => {
                handleMenuItemClick(item.text); // Set active item on click
                handleMenuToggle(item.text); // Toggle submenu if it exists
              }}
            >
              <SidebarItem
                key={item.text}
                text={item.text}
                iconClass={item.iconClass}
                active={activeMenuItem === item.text}
                submenu={
                  Array.isArray(item.submenuItems) &&
                  item.submenuItems.length > 0
                }
                expanded={expandedMenu === item.text}
              />
            </NavLink>

            {/* Submenu items */}
            {item.submenuItems && (
              <ul
                className={`submenu ${
                  expandedMenu === item.text ? "expanded" : ""
                }`}
              >
                {item.submenuItems.map((subItem) => (
                  <NavLink
                    to={subItem.path}
                    key={subItem.text}
                    className="submenu-item"
                    onClick={() => handleMenuItemClick(subItem.text)}
                  >
                    <SidebarItem
                      key={subItem.text}
                      text={subItem.text}
                      active={activeMenuItem === subItem.text}
                      iconClass={subItem.iconClass}
                      submenu={false}
                      expanded={false}
                    />
                  </NavLink>
                ))}
              </ul>
            )}
          </div>
        ))}
      </ul>
      <div className="theme-switcher">
        <Button
          onClick={() => setTheme("night")}
          icon="pi pi-search"
          severity="success"
        />
        &nbsp;
        <Button
          onClick={() => setTheme("dark")}
          icon="pi pi-bookmark"
          severity="help"
        />
        &nbsp;
        <Button
          onClick={() => setTheme("light")}
          icon="pi pi-user"
          severity="info"
        />
        &nbsp;
        <Button
          onClick={() => setTheme("vivid")}
          icon="pi pi-heart"
          severity="danger"
        />
      </div>
    </div>
  );
};

export default Sidebar;
