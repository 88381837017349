import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BalanceChart } from "../../interfaces";
import { MainLoader } from "../../common";

interface BalanceReportTableProps {
  data: BalanceChart[] | undefined;
  isLoading: boolean;
}

const BalanceReportTable: React.FC<BalanceReportTableProps> = ({
  data,
  isLoading,
}) => {
  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card flex-table-w60">
          <DataTable
            value={data}
            header="MONTHLY BALANCE"
            stripedRows
            showGridlines
            scrollable
            scrollHeight="30vh"
            rows={10}
            size="small"
            dataKey="END_DATE"
          >
            <Column
              field="END_DATE"
              header="End Date"
              body={(rowData) => rowData.END_DATE.slice(0, -9)}
            />
            {/* You can dynamically render additional columns based on your data keys */}
            {Object.keys(data?.[0] || {})
              .filter(
                (key) =>
                  key !== "END_DATE" &&
                  key !== "TotalBalance" &&
                  key !== "sequentialId"
              )
              .map((key) => (
                <Column
                  key={key}
                  field={key}
                  header={key}
                  body={(rowData) => {
                    const value = rowData[key];
                    return value !== null ? Math.floor(value) : value;
                  }}
                />
              ))}
            <Column
              field="TotalBalance"
              header="Total"
              body={(val) => val.TotalBalance.toFixed(2)}
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default BalanceReportTable;
