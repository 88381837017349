export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getContrastingColor = (index: number) => {
  const r = (index * 50 * Math.floor(Math.random() * 50)) % 256; // Cycle through 0-255 with randomness
  const g = (index * 75 * Math.floor(Math.random() * 50)) % 256;
  const b = (index * 100 * Math.floor(Math.random() * 50)) % 256;

  return `rgb(${r}, ${g}, ${b})`;
};

const colorPalette = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#FF33A6",
  "#A6FF33",
  "#5733FF",
  "#33A6FF",
];

export const getColorFromPalette = (index: number) => {
  return colorPalette[index % colorPalette.length];
};
