import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GenericResponse } from "../interfaces";

const staticApi = createApi({
  reducerPath: "staticApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.financemanager.co.in/api",
  }),

  endpoints: (builder) => ({
    getContributors: builder.query<string[], void>({
      query: () => ({
        url: "static/contributors",
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GenericResponse<string[]>) =>
        response.result,
    }),
  }),
});

export const { useGetContributorsQuery } = staticApi;

export default staticApi;
