import WelcomeUser from "../components/header/WelcomeUser";
import { SmartCard } from "../components/smartcard";
import { AccountTransactions } from "../data";

import "../styles/templates/transaction.css";
import { useGetMonthlyReportQuery } from "../services/reportsApi";

function EditTransaction() {
  const { data, isLoading } = useGetMonthlyReportQuery();
  const financialData = data?.result.slice(0, 2);

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={isLoading} />
      </div>
      <div className="statements-panel">
        <AccountTransactions />
      </div>
    </div>
  );
}

export default EditTransaction;
