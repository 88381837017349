import React, { useEffect, useState } from "react";
import { SelectFormField } from "../components/formcontrols";
import { DropdownChangeEvent } from "primereact/dropdown";
import {
  getActiveYears,
  getCurrentMonth,
  getCurrentYear,
  getMonths,
} from "../utility";
import "../styles/forms/monthlyreport.css";
import { YearMonth } from "../interfaces";

interface MonthlyReportFormProps {
  onYearMonthChange: (yearMonth: YearMonth) => void; // Prop for passing data back
}

const MonthlyReportForm: React.FC<MonthlyReportFormProps> = ({
  onYearMonthChange,
}) => {
  const [yearMonth, setYearMonth] = useState<YearMonth>({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });

  const handleMonthChange = (e: DropdownChangeEvent) => {
    const { name, value } = e.target;
    setYearMonth((prev) => ({
      ...prev,
      [name]: getMonths().find((m) => m[1] === value)?.[0],
    }));
  };

  const handleYearChange = (e: DropdownChangeEvent) => {
    const { name, value } = e.target;
    setYearMonth((prev) => ({
      ...prev,
      [name]: parseInt(value, 10),
    }));
  };

  useEffect(() => {
    onYearMonthChange(yearMonth);
  }, [yearMonth, onYearMonthChange]);

  return (
    <div className="monthlyreport-form">
      <h3>Generate Report</h3>
      <form>
        <ul>
          <li>
            <SelectFormField
              label="Select Month"
              name="month"
              value={getMonths().find((m) => m[0] === yearMonth.month)?.[1]}
              options={getMonths().map((month) => month[1])}
              optionLabel="month"
              onChange={handleMonthChange}
              editable={false}
            />
          </li>
          <li>
            <SelectFormField
              label="Select Year"
              name="year"
              value={yearMonth.year.toString()}
              options={getActiveYears()}
              optionLabel="year"
              onChange={handleYearChange}
              editable={false}
            />
          </li>
        </ul>
      </form>
    </div>
  );
};

export default MonthlyReportForm;
