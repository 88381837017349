import React, { FC, SelectHTMLAttributes } from "react";

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string; // Optional label for the select box
  options: { value: string; label: string }[]; // Array of options for the select box
}

const SelectBoxField: FC<SelectFieldProps> = ({ label, options, ...props }) => {
  return (
    <div className="select-container">
      {label && <label htmlFor={props.id}>{label}</label>}
      <select className="select-field" {...props}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBoxField;
