import "../styles/dashboard/overview.css";
import "../styles/dashboard/smartcarditem.css";
import "../styles/dashboard/table.css";
import "../styles/fonts/nucleo-icons.css";
import "../styles/fonts/nucleo-svg.css";

import { SmartCard } from "../components/smartcard";
import WelcomeUser from "../components/header/WelcomeUser";
import { hocAuthenticate } from "../components/hoc";
import { Accounts, MonthlyReportChart, RecentTransactions } from "../data";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux";
import { useEffect, useState } from "react";

function Dashboard() {
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );
  const [isLoading, setIsLoading] = useState(financialData.length === 0);

  useEffect(() => {
    setIsLoading(financialData.length === 0);
  }, [financialData]);

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={isLoading} />
      </div>
      <div className="visualizer-panel">
        <div className="graphs-panel">
          <MonthlyReportChart />
        </div>
      </div>
      <div className="data-panel">
        <div className="data-viewport">
          <Accounts />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default hocAuthenticate(Dashboard);
