import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KakeiboTracker } from "../../interfaces";
import { statisticsApi } from "../../services";

interface KakeiboReportState {
  kakeiboTracker: KakeiboTracker[];
}

const initialState: KakeiboReportState = {
  kakeiboTracker: [],
};

export const kakeiboSlice = createSlice({
  name: "kakeibotracker",
  initialState: initialState,
  reducers: {
    setKakeiboTracker: (state, action: PayloadAction<KakeiboTracker[]>) => {
      state.kakeiboTracker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      statisticsApi.endpoints.getKakeiboTracker.matchFulfilled,
      (state, { payload }) => {
        state.kakeiboTracker = payload.result.slice(0, 2);
      }
    );
  },
});

export const { setKakeiboTracker } = kakeiboSlice.actions;
export const kakeiboTrackerReducer = kakeiboSlice.reducer;
