import { CommonTransactions } from "../data";
import WelcomeUser from "../components/header/WelcomeUser";
import TransactionForm from "../containers/TransactionForm";
import { useState } from "react";
import { ICommonTransaction } from "../interfaces";
import { SmartCard } from "../components/smartcard";

import "../styles/templates/transaction.css";
import { useGetMonthlyReportQuery } from "../services/reportsApi";

function AddTransaction() {
  const [selectedTransaction, setSelectedTransaction] =
    useState<ICommonTransaction>({
      account: "",
      type: "",
      component: "",
      description: "",
      classifier: "",
      mode: "",
      people: "",
      state: "A",
      total: 0,
    });

  const { data, isLoading } = useGetMonthlyReportQuery();
  const financialData = data?.result.slice(0, 2);

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={isLoading} />
      </div>
      <div className="transact-form-panel">
        <TransactionForm commonTransaction={selectedTransaction} />
      </div>
      <CommonTransactions onClickTransaction={setSelectedTransaction} />
    </div>
  );
}

export default AddTransaction;
