import { Bar } from "react-chartjs-2";
import { BalanceChart } from "../../interfaces";
import { MainLoader } from "../../common";

interface BalanceReportProps {
  data: BalanceChart[] | undefined;
  isLoading: boolean;
}

const AccountBalanceChart: React.FC<BalanceReportProps> = (props) => {
  const rootStyle = getComputedStyle(document.documentElement);
  const barBackgroundColor = rootStyle.getPropertyValue("--gamma-color");

  const barLabels: string[] = Object.keys(
    props.data ? props.data[0] : String
  ).filter(
    (key) =>
      key !== "END_DATE" && key !== "TotalBalance" && key !== "sequentialId"
  );

  const barValues = Object.values(props.data ? props.data[0] : Number).filter(
    (_, index) => index > 0 && index < barLabels.length + 1
  ) as number[];

  const barChartData = {
    labels: barLabels,
    datasets: [
      {
        label: "Account Balance",
        data: barValues,
        backgroundColor: barBackgroundColor, // Bar color rgba(75, 192, 192, 0.6)
        // borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 0,
        barThickness: 25,
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Amount",
        },
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          callback: (tickValue: string | number) => {
            const value =
              typeof tickValue === "number" ? tickValue : parseFloat(tickValue);
            return value >= 100000 ? `${(value / 100000).toFixed(0)} L` : value;
          },
        },
      },
      x: {
        title: {
          display: false,
          text: "Account",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "ACCOUNT BALANCE",
        font: {
          size: 16,
          weight: 400,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
  };

  return (
    <>
      {props.isLoading && <MainLoader />}
      {!props.isLoading && (
        <div
          style={{
            width: "35%",
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Bar data={barChartData} options={barOptions} />
        </div>
      )}
    </>
  );
};

export default AccountBalanceChart;
