import {
  GenericResponse,
  ICommonTransfer,
  IDeleteTransfer,
  ITransfer,
  ITransferForm,
} from "../interfaces";
import { Tags } from "../store/tags";
import financeApi from "./financeApi";

const transfersApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransfers: builder.query<GenericResponse<ITransfer[]>, void>({
      query: () => ({
        url: "transfers/all",
        params: {},
      }),
    }),

    getCommonTransfers: builder.query<GenericResponse<ICommonTransfer[]>, void>(
      {
        query: () => ({
          url: "ctequery/commontransfers",
          method: "GET",
          params: {},
        }),
        transformResponse: (response: GenericResponse<ICommonTransfer[]>) => {
          const modifiedResult = response?.result.map((item, index) => ({
            ...item,
            sequentialId: index + 1,
          }));

          return {
            ...response,
            result: modifiedResult,
          };
        },
      }
    ),

    addTransferRecord: builder.mutation({
      query: (transferData: ITransferForm) => ({
        url: "transfers",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: transferData,
      }),
      invalidatesTags: [Tags.AccountsView],
    }),

    deleteTransferRecord: builder.mutation({
      query: (transferData: IDeleteTransfer) => ({
        url: "transfers/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: transferData,
      }),
      invalidatesTags: [Tags.AccountsView],
    }),
  }),
});

export const {
  useGetAllTransfersQuery,
  useGetCommonTransfersQuery,
  useAddTransferRecordMutation,
  useDeleteTransferRecordMutation,
} = transfersApi;
export default transfersApi;
