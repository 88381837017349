import React from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

interface FormSelectProps {
  label: string;
  name: string;
  value?: string | null;
  options: Array<{ [key: string]: any }> | string[];
  optionLabel: string;
  onChange: (e: DropdownChangeEvent) => void;
  placeholder?: string;
  className?: string;
  editable?: boolean;
  required?: boolean;
}

const SelectFormField: React.FC<FormSelectProps> = ({
  label,
  name,
  value,
  options,
  optionLabel,
  onChange,
  placeholder = "Select an option",
  className = "p-inputtext-sm",
  editable = true,
  required = true,
}) => {
  return (
    <div>
      <label>{label}</label>
      <Dropdown
        value={value || ""}
        options={options}
        optionLabel={optionLabel}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        editable={editable}
        required={required}
      />
    </div>
  );
};

export default SelectFormField;
