import { useEffect, useState } from "react";
import { SelectFormField } from "../components/formcontrols";
import { useGetComponentsQuery } from "../services/componentsApi";
import { DropdownChangeEvent } from "primereact/dropdown";
import { useGetComponentStatisticsQuery } from "../services/statisticsApi";
import { ItemStatisticsChart } from "../data";
import { MainLoader } from "../common";
import "../styles/forms/statistics.css";

function ComponentForm() {
  const { data: componentNames, isLoading: isLoadingComponents } =
    useGetComponentsQuery();

  const [selection, setSelection] = useState({
    item: undefined as string | undefined,
    type: "EXPENSE" as string,
  });

  const { data: componentGraph, isFetching } = useGetComponentStatisticsQuery(
    selection.item!,
    {
      skip: !selection.item,
    }
  );

  useEffect(() => {
    if (componentNames?.length) {
      setSelection((prev) => ({ ...prev, item: componentNames[1] }));
    }
  }, [componentNames]);

  const handleSelectChange = (e: DropdownChangeEvent) => {
    const { name, value } = e.target;
    setSelection((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {isLoadingComponents && <MainLoader />}
      {!isLoadingComponents && (
        <>
          <div className="component-stat-form">
            <h5>SELECT COMPONENT ITEM</h5>
            <form className="transact-form">
              <ul>
                <li>
                  <SelectFormField
                    label="Component"
                    name="item"
                    value={selection.item}
                    options={componentNames || []}
                    optionLabel="component"
                    onChange={handleSelectChange}
                  />
                </li>
                <li>
                  <SelectFormField
                    label="Type"
                    name="type"
                    value={selection.type}
                    options={["EXPENSE", "INCOME"]}
                    optionLabel="type"
                    onChange={handleSelectChange}
                  />
                </li>
              </ul>
            </form>
          </div>

          <div className="component-stats-graph">
            <ItemStatisticsChart
              data={componentGraph?.result}
              type={selection.type}
              isFetching={isFetching}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ComponentForm;
