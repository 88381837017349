import { useGetBalanceChartQuery } from "../services/reportsApi";
import WelcomeUser from "../components/header/WelcomeUser";
import { SmartCard } from "../components/smartcard";
import {
  AccountBalanceChart,
  BalanceReportChart,
  BalanceReportTable,
  TotalBalance,
} from "../data";
import { hocAuthenticate } from "../components/hoc";
import { getCurrentDate } from "../utility";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux";

function Reports() {
  const { data: balancechart, isLoading } = useGetBalanceChartQuery();
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={false} />
      </div>
      <div className="visualizer-panel">
        <div className="graphs-panel">
          <AccountBalanceChart
            data={balancechart?.result}
            isLoading={isLoading}
          />
          <BalanceReportChart
            data={balancechart?.result} // .slice(0, 36)
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="data-panel">
        <div className="data-viewport">
          <TotalBalance effectiveDate={getCurrentDate()} />
          <BalanceReportTable
            data={balancechart?.result}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default hocAuthenticate(Reports);
