import React, { useState } from "react";
import "../styles/forms/login.css";
import { InputTextField } from "../components/formcontrols";
import { inputHelper, toastNotify } from "../utility";
import { ApiResponse, UserModel } from "../interfaces";
import { useLoginUserMutation } from "../services/authApi";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../store/slice/userAuthSlice";
import { useNavigate } from "react-router-dom";
import { MainLoader } from "../common";

function LoginForm() {
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    username: "",
    password: "",
  });

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  const handleLoginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response: ApiResponse = await loginUser({
      username: userInput.username,
      password: userInput.password,
    });

    if (response.data) {
      toastNotify("User Logged-in successfully!");
      const { token } = response.data.result;
      const { fullName, id, email, role } = jwtDecode<UserModel>(token);
      localStorage.setItem("token", token);
      dispatch(setLoggedInUser({ fullName, id, email, role }));
      navigate("/dashboard");
    } else if (response.error) {
      toastNotify(response.error.data.errorMessages[0], "error");
      setError(response.error.data.errorMessages[0]);
    }

    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form className="login-form" method="POST" onSubmit={handleLoginUser}>
        <h3>Login Here</h3>
        <InputTextField
          className="login-input"
          label="Username"
          type="text"
          name="username"
          id="username"
          value={userInput.username}
          onChange={handleUserInput}
        />
        <InputTextField
          className="login-input"
          label="Password"
          type="password"
          name="password"
          id="password"
          value={userInput.password}
          onChange={handleUserInput}
        />
        {error && <p>{error}</p>}
        <button className="login-button" type="submit">
          Log In
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
