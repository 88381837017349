import { hocAuthenticate } from "../../components/hoc";
import { useGetAllAccountQuery } from "../../services/accountsApi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { AccountData } from "../../interfaces";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAccountCode } from "../../store/slice/accountSlice";
import { MainLoader, CountryFlag } from "../../common";
import { getCurrencySymbol, getCountryCode } from "../../utility";

function Accounts() {
  const { data, isLoading } = useGetAllAccountQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data?.result) {
      dispatch(setAccountCode(data?.result.map((code) => code.accountcode)));
    }
  }, [isLoading, data?.result, dispatch]);

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div className="card dashboard-tables">
          <DataTable
            value={data?.result || []}
            header="ACCOUNTS"
            stripedRows
            showGridlines
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            scrollable
            scrollHeight="35vh"
          >
            <Column field="sequentialId" header="ID"></Column>
            <Column
              field="name"
              header="Name"
              body={(rowData: AccountData) => {
                const countryCode = getCountryCode(rowData.currencyid);
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CountryFlag countryCode={countryCode} />
                    <span style={{ marginLeft: "8px" }}>{rowData.name}</span>
                  </div>
                );
              }}
            ></Column>
            <Column field="accountcode" header="Code"></Column>
            <Column
              field="lasttransactiondate"
              header="Last Activity Date"
            ></Column>
            <Column
              field="balance"
              header="Balance"
              body={(rowData: AccountData) =>
                `${getCurrencySymbol(
                  rowData.currencyid
                )} ${rowData.balance.toLocaleString()}`
              }
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default hocAuthenticate(Accounts);
