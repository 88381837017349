import { useSelector } from "react-redux";
import WelcomeUser from "../components/header/WelcomeUser";
import { SmartCard } from "../components/smartcard";
import AccountStatements from "../data/tabviews/AccountStatements";
import { RootState } from "../store/redux";

function Statements() {
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );

  return (
    <div className="overview-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={false} />
      </div>
      <div className="statements-panel">
        <AccountStatements />
      </div>
    </div>
  );
}

export default Statements;
