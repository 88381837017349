import {
  ComponentGraph,
  ComponentStat,
  GenericResponse,
  KakeiboByMonth,
  KakeiboTracker,
  YearMonth,
} from "../interfaces";
import financeApi from "./financeApi";

const statisticsApi = financeApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomeByComponent: builder.query<
      GenericResponse<ComponentStat[]>,
      YearMonth
    >({
      query: (yymm) => ({
        url: "/statistics/incomebycomponent",
        params: { year: yymm.year, month: yymm.month },
      }),
    }),

    getExpenseByComponent: builder.query<
      GenericResponse<ComponentStat[]>,
      YearMonth
    >({
      query: (yymm) => ({
        url: "/statistics/expensebycomponent",
        params: { year: yymm.year, month: yymm.month },
      }),
    }),

    getComponentStatistics: builder.query<
      GenericResponse<ComponentGraph[]>,
      string
    >({
      query: (component) => ({
        url: "/statistics/componentstats",
        params: { item: component },
      }),
    }),

    getKakeiboTracker: builder.query<GenericResponse<KakeiboTracker[]>, void>({
      query: () => ({
        url: "/dynamicsql/kakeibotracker",
        params: {},
      }),
    }),

    getKakeiboByMonth: builder.query<
      GenericResponse<KakeiboByMonth[]>,
      YearMonth
    >({
      query: (yymm) => ({
        url: "/dynamicsql/kakeibobymonth",
        params: { year: yymm.year, month: yymm.month },
      }),
    }),
  }),
});

export const {
  useGetIncomeByComponentQuery,
  useGetExpenseByComponentQuery,
  useGetComponentStatisticsQuery,
  useGetKakeiboTrackerQuery,
  useGetKakeiboByMonthQuery,
} = statisticsApi;
export default statisticsApi;
