import { RootState } from "../../store/redux";
import { UserModel } from "../../interfaces";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../styles/dashboard/welcome.css";
import {
  setLoggedInUser,
  emptyUserState,
} from "../../store/slice/userAuthSlice";

function WelcomeUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData: UserModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  const handleLogoutUser = () => {
    localStorage.removeItem("token");
    dispatch(setLoggedInUser({ ...emptyUserState }));
    navigate("/");
  };

  return (
    <div className="welcome-panel">
      <ul className="welcome-ui">
        <li>
          <i className="fa fa-user-circle" aria-hidden="true"></i>
          &nbsp;Welcome User: {userData.fullName}
        </li>
        {userData.id && (
          <li>
            <button className="logout-user" onClick={handleLogoutUser}>
              <i className="fas fa-sign-out-alt"></i>&nbsp;Logout
            </button>
          </li>
        )}
        {!userData.id && (
          <li>
            <p>
              <NavLink to="/" className="login-user">
                <i className="fa fa-paper-plane"></i>
                &nbsp;Login
              </NavLink>
            </p>
          </li>
        )}
      </ul>
    </div>
  );
}

export default WelcomeUser;
