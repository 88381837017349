import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authApi, financeApi, staticApi, componentsApi } from "../services";
import { userAuthReducer, accountCodeReducer } from "./slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { monthlyReportReducer } from "./slice/monthlyReportSlice";
import { kakeiboTrackerReducer } from "./slice/kakeiboSlice";

const accountPersistConfig = {
  key: "accountcode",
  storage,
};

const monthlyReportConfig = {
  key: "componentcode",
  storage,
};

const kakeiboTrackerConfig = {
  key: "kakeibotracker",
  storage,
};

const rootReducer = combineReducers({
  userAuthStore: userAuthReducer,
  accountCodeStore: persistReducer(accountPersistConfig, accountCodeReducer),
  monthlyReportStore: persistReducer(monthlyReportConfig, monthlyReportReducer),
  kakeiboStore: persistReducer(kakeiboTrackerConfig, kakeiboTrackerReducer),
  [authApi.reducerPath]: authApi.reducer,
  [financeApi.reducerPath]: financeApi.reducer,
  [staticApi.reducerPath]: staticApi.reducer,
  [componentsApi.reducerPath]: componentsApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"], // ignore these actions
      },
    })
      .concat(authApi.middleware)
      .concat(financeApi.middleware)
      .concat(staticApi.middleware)
      .concat(componentsApi.middleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export default store;
