import React, { useEffect, useState } from "react";
import { YearMonth } from "../../interfaces";
import { useGetKakeiboByMonthQuery } from "../../services/statisticsApi";
import { MainLoader } from "../../common";
import { Chart } from "primereact/chart";

const KakeiboMonthlyChart: React.FC<YearMonth> = React.memo((props) => {
  console.log("Kakeibo Pie chart");
  const { data: kakeiboData, isLoading } = useGetKakeiboByMonthQuery(props);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (kakeiboData) {
      const data = {
        labels: kakeiboData.result.map((item) => item.Kakeibo),
        datasets: [
          {
            data: kakeiboData.result.map((item) => item.Amount),
          },
        ],
      };

      const options = {
        cutout: "75%",
        plugins: {
          title: {
            display: true,
            text: "KAKEIBO TRACKER",
            padding: {
              bottom: 20,
            },
            font: {
              size: 14,
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [kakeiboData]);

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <div
          className="card"
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Chart
            type="doughnut"
            data={chartData}
            options={chartOptions}
            style={{ height: "40vh", width: "90%" }}
          />
        </div>
      )}
    </>
  );
});

export default KakeiboMonthlyChart;
