import { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { useGetMonthlyReportQuery } from "../../services/reportsApi";
import { MainLoader } from "../../common";
import { convertYearMonthToMonth } from "../../utility";

function MonthlyReportChart() {
  const { data, isLoading } = useGetMonthlyReportQuery();

  const [netChartData, setNetChartData] = useState({});
  const [totalChartData, setTotalChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (data) {
      const labels = data.result.map((item) =>
        convertYearMonthToMonth(item.yearmonth.toString())
      );
      const totalIncome = data.result.map((item) => item.totalincome);
      const totalExpense = data.result.map((item) => item.totalexpense);
      const totalSavings = data.result.map((item) => item.totalsavings);
      const netIncome = data.result.map((item) => item.netincome);
      const netExpense = data.result.map((item) => item.netexpense);
      const netSavings = data.result.map((item) => item.netsavings);

      const totalReport = {
        labels: labels,
        datasets: [
          {
            label: "Total Income",
            data: totalIncome,
            borderColor: "#66BB6A",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
          {
            label: "Total Expense",
            data: totalExpense,
            borderColor: "#FF6384",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
          {
            label: "Total Savings",
            data: totalSavings,
            borderColor: "#9C27B0",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
        ],
      };

      const netReport = {
        labels: labels,
        datasets: [
          {
            label: "Net Income",
            data: netIncome,
            borderColor: "#42A5F5",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
          {
            label: "Net Expense",
            data: netExpense,
            borderColor: "#E91E63",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
          {
            label: "Net Savings",
            data: netSavings,
            borderColor: "#FFA726",
            fill: false,
            tension: 0.4,
            pointRadius: 2,
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "FINANCIAL OVERVIEW",
            font: {
              size: 14,
            },
            padding: {
              top: 20,
              bottom: 20,
            },
          },
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: {
            title: {
              display: false,
              text: "Year-Month",
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Amount",
            },
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              callback: (tickValue: string | number) => {
                const value =
                  typeof tickValue === "number"
                    ? tickValue
                    : parseFloat(tickValue);
                return `${(value / 100000).toFixed(0)} L`;
              },
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 30,
            bottom: 30,
            left: 20,
          },
        },
      };

      setNetChartData(netReport);
      setTotalChartData(totalReport);
      setChartOptions(options);
    }
  }, [data]);

  // Options for customizing the chart

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && (
        <>
          <div
            style={{
              width: "45%",
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
          >
            <Chart
              type="line"
              data={netChartData}
              options={chartOptions}
              style={{ height: "40vh" }}
            />
          </div>
          <div
            style={{
              width: "45%",
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
          >
            <Chart
              type="line"
              data={totalChartData}
              options={chartOptions}
              style={{ height: "40vh" }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default MonthlyReportChart;
