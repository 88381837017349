import React from "react";

interface SidebarItemProps {
  text: string;
  iconClass: string;
  active: boolean;
  submenu: boolean;
  expanded: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  text,
  iconClass,
  active,
  submenu,
  expanded,
}) => {
  return (
    <li className={`menu-item sidenav-li ${active ? "active" : ""}`}>
      <i className={iconClass}></i>
      <span>&nbsp;&nbsp;{text}</span>
      {submenu &&
        (expanded ? (
          <i className="fas fa-chevron-down submenu-icon"></i>
        ) : (
          <i className="fas fa-chevron-right submenu-icon"></i>
        ))}
    </li>
  );
};

export default SidebarItem;
