import React, { ChangeEvent, useEffect, useState } from "react";
import { ApiResponse, ICommonTransfer, ITransferForm } from "../interfaces";
import { useGetAllAccountQuery } from "../services/accountsApi";
import { useGetTransactionModesQuery } from "../services/transactionsApi";
import { useGetComponentsQuery } from "../services/componentsApi";
import { useAddTransferRecordMutation } from "../services/transfersApi";
import { DropdownChangeEvent } from "primereact/dropdown";
import { toastNotify } from "../utility";
import { MainLoader } from "../common";
import { InputFormField, SelectFormField } from "../components/formcontrols";

interface TransferFormProps {
  commonTransfer: ICommonTransfer | undefined;
}

const TransferForm: React.FC<TransferFormProps> = ({ commonTransfer }) => {
  const { data: accountCodes } = useGetAllAccountQuery();
  const { data: transactModes } = useGetTransactionModesQuery();
  const { data: componentNames } = useGetComponentsQuery();
  const [addTransfer] = useAddTransferRecordMutation();

  const [loading, setLoading] = useState(false);
  const accounts = accountCodes?.result.map((acc) => acc.name);
  const [transfer, setTransfer] = useState<ITransferForm | null>(() => {
    return {
      ...(commonTransfer || {}),
      date: new Date().toISOString().split("T")[0],
      exchangerate: 1.0,
      exchangeamount: 0.0,
      group: "",
      state: "A",
    } as ITransferForm;
  });

  useEffect(() => {
    if (commonTransfer) {
      setTransfer((prev) => {
        const currentTransfer: ITransferForm = {
          date: prev?.date || new Date().toISOString().split("T")[0],
          exchangerate: prev?.exchangerate || 0,
          group: prev?.group || "",
          exchangeamount: (prev?.amount || 0) * (prev?.exchangerate || 1),
          state: "A",

          sourceaccount: commonTransfer.sourceaccount,
          targetaccount: commonTransfer.targetaccount,
          description: commonTransfer.description,
          component: commonTransfer.component,
          mode: commonTransfer.mode,
          amount: commonTransfer.amount,
          servicecharge: commonTransfer.servicecharge,
        };

        return currentTransfer;
      });
    }
  }, [commonTransfer]);

  const handleSelectChange = (e: DropdownChangeEvent) => {
    setTransfer(
      (prev) =>
        ({
          ...prev,
          [e.target.name]: e.target.value,
        } as ITransferForm)
    );
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTransfer((prev) => {
      const currentTransfer = { ...prev };

      if (name === "exchangerate" && currentTransfer.amount) {
        currentTransfer.exchangeamount =
          currentTransfer.amount * parseFloat(value);
      }

      if (name === "amount" && currentTransfer.exchangerate) {
        currentTransfer.exchangeamount =
          parseFloat(value) * currentTransfer.exchangerate;
      }

      return {
        ...currentTransfer,
        [name]: value,
      } as ITransferForm;
    });
  };

  const handleRecordTransfer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (transfer) {
        const response: ApiResponse = await addTransfer(transfer);

        if (
          response.data?.issuccess &&
          parseInt(response.data?.result?.returnvalue) >= 0
        ) {
          toastNotify("Transfer Record added !");
        } else if (response.error) {
          toastNotify(response.error.data.errormessages[0], "error");
        }
      }
    } catch (error) {
      toastNotify("Transfer error", "error");
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form
        className="transact-form"
        method="POST"
        onSubmit={handleRecordTransfer}
      >
        <h3>Add Transfer</h3>
        <div>
          <ul>
            <li>
              <SelectFormField
                label="Source Account"
                name="sourceaccount"
                value={transfer?.sourceaccount}
                options={accounts || []}
                optionLabel="sourceaccount"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Target Account"
                name="targetaccount"
                value={transfer?.targetaccount}
                options={accounts || []}
                optionLabel="targetaccount"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <InputFormField
                label="Description"
                name="description"
                value={transfer?.description}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Transfer Amount"
                name="amount"
                value={transfer?.amount.toString()}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Transfer Date"
                name="date"
                value={transfer?.date.toString()}
                onChange={handleTextChange}
              />
            </li>
          </ul>
          <ul>
            <li>
              <SelectFormField
                label="Component"
                name="component"
                value={transfer?.component}
                options={componentNames || []}
                optionLabel="component"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Transfer Mode"
                name="mode"
                value={transfer?.mode}
                options={transactModes || []}
                optionLabel="mode"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <InputFormField
                label="Service Charge"
                name="servicecharge"
                value={transfer?.servicecharge.toString()}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Exchange Rate"
                name="exchangerate"
                value={transfer?.exchangerate.toString()}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Exchange Amount"
                name="exchangeamount"
                value={transfer?.exchangeamount.toString()}
                onChange={handleTextChange}
                disabled={true}
              />
            </li>
          </ul>
        </div>
        <button className="transact-form-button" type="submit">
          Record Transfer
        </button>
      </form>
    </>
  );
};

export default TransferForm;
