import React from "react";
import { SmartCardItem } from "../dashboard";
import { MonthlyReport } from "../../interfaces";
import { calcPercentChange } from "../../utility";

interface SmartCardProps {
  data: MonthlyReport[] | undefined;
  isLoading: boolean;
}

const SmartCard: React.FC<SmartCardProps> = (props) => {
  const currentMonth = props?.data?.[0];
  const previousMonth = props?.data?.[1];

  const incomeChange = calcPercentChange(
    currentMonth?.totalincome || 0,
    previousMonth?.totalincome || 0
  );
  const expenseChange = calcPercentChange(
    currentMonth?.totalexpense || 0,
    previousMonth?.totalexpense || 0
  );
  const savingsChange = calcPercentChange(
    currentMonth?.totalsavings || 0,
    previousMonth?.totalsavings || 0
  );

  const smartCardItems = [
    {
      id: 1,
      title: "MONTHLY EXPENSE",
      amount: props.isLoading
        ? "Loading..."
        : `₹ ${currentMonth?.totalexpense?.toFixed(2)}`,
      iconClass: "ni ni-money-coins icon-color",
      trend: `${expenseChange.toFixed(0)}% `,
      trendStyle: expenseChange < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon: expenseChange < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style expense-color",
      iconColor: "",
    },
    {
      id: 2,
      title: "MONTHLY INCOME",
      amount: props.isLoading
        ? "Loading..."
        : `₹ ${currentMonth?.totalincome?.toFixed(2)}`,
      iconClass: "ni ni-world icon-color",
      trend: `${incomeChange.toFixed(0)}% `,
      trendStyle: incomeChange < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon: incomeChange < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style income-color",
      iconColor: "",
    },
    {
      id: 3,
      title: "MONTHLY SAVINGS",
      amount: props.isLoading
        ? "Loading..."
        : `₹ ${currentMonth?.totalsavings?.toFixed(2)}`,
      iconClass: "ni ni-paper-diploma savings-color",
      trend: `${savingsChange.toFixed(0)}% `,
      trendStyle: savingsChange < 0 ? { color: "red" } : { color: "#18c379" },
      trendIcon: savingsChange < 0 ? "fa fa-arrow-down" : "fa fa-arrow-up",
      iconStyle: "icon-style savings-color",
      iconColor: "",
    },
    {
      id: 4,
      title: "ZAKAT",
      amount: "$5500.00",
      iconClass: "ni ni-cart icon-color",
      trend: "+5%",
      iconStyle: "icon-style zakat-color",
      iconColor: "",
    },
  ];

  return (
    <div className="smartcard-container">
      {smartCardItems.map((item) => (
        <SmartCardItem
          key={item.id} // important to avoid console warning
          title={item.title}
          dataPoint={item.amount}
          trend={item.trend}
          trendStyle={item.trendStyle}
          trendIcon={item.trendIcon}
          iconClass={item.iconClass}
          iconColor={item.iconColor}
          iconStyle={item.iconStyle}
        />
      ))}
    </div>
  );
};

export default SmartCard;
