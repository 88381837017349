import WelcomeUser from "../components/header/WelcomeUser";
import { SmartCard } from "../components/smartcard";
import { AccountTransfers } from "../data";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux";
import "../styles/templates/transaction.css";

function EditTransfer() {
  const financialData = useSelector(
    (state: RootState) => state.monthlyReportStore.financeOverview
  );

  return (
    <div className="transact-container">
      <div className="overview-header">
        <WelcomeUser />
      </div>
      <div className="insights-panel">
        <SmartCard data={financialData} isLoading={false} />
      </div>
      <div className="statements-panel">
        <AccountTransfers />
      </div>
    </div>
  );
}

export default EditTransfer;
