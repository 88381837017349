import React, { FC, InputHTMLAttributes } from "react";
import "../../styles/forms/form-controls.css";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string; // Optional label for the input
  labelClass?: string;
}

const InputTextField: FC<InputFieldProps> = ({ label, ...props }) => {
  return (
    <div className="input-container">
      {label && <label htmlFor={props.id}>{label}</label>}
      <input className="input-field" {...props} />
    </div>
  );
};

export default InputTextField;
