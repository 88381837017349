import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ApiResponse,
  ICommonTransaction,
  ITransactionForm,
} from "../interfaces";
import { useGetAllAccountQuery } from "../services/accountsApi";
import {
  useGetTransactionTypesQuery,
  useGetTransactionModesQuery,
  useAddTransactionRecordMutation,
  useGetTransactionGroupsQuery,
} from "../services/transactionsApi";
import { useGetComponentsQuery } from "../services/componentsApi";
import { DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputFormField, SelectFormField } from "../components/formcontrols";

import "../styles/forms/transaction.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { toastNotify } from "../utility";
import { MainLoader } from "../common";
import { useGetContributorsQuery } from "../services/staticApi";

interface TransactionFormProps {
  commonTransaction: ICommonTransaction | undefined;
}

const TransactionForm: React.FC<TransactionFormProps> = ({
  commonTransaction,
}) => {
  const { data: accountCodes } = useGetAllAccountQuery();
  const { data: transactTypes } = useGetTransactionTypesQuery();
  const { data: transactModes } = useGetTransactionModesQuery();
  const { data: transactGroups } = useGetTransactionGroupsQuery();
  const { data: componentNames } = useGetComponentsQuery();
  const { data: contributors } = useGetContributorsQuery();
  const [addTransaction] = useAddTransactionRecordMutation();
  const [loading, setLoading] = useState(false);

  const accounts = accountCodes?.result.map((acc) => acc.name);

  const [transaction, setTransaction] = useState<ITransactionForm | null>(
    () => {
      return {
        ...(commonTransaction || {}),
        trndate: new Date(),
        currencycode: "",
        amount: "",
        effectivedate: new Date(),
        transactgroup: "",
        quantity: "",
        xtracharge: "",
        xtrachargedesc: "",
        refund: "",
        exchangerate: "",
      } as ITransactionForm;
    }
  );

  useEffect(() => {
    if (commonTransaction) {
      setTransaction((prev) => {
        const currentTransaction: ITransactionForm = {
          trndate: prev?.trndate || new Date(),
          currencycode: prev?.currencycode || "",
          effectivedate: prev?.effectivedate || new Date(),
          transactgroup: prev?.transactgroup || "",
          quantity: prev?.quantity || "",
          xtracharge: prev?.xtracharge || "",
          xtrachargedesc: prev?.xtrachargedesc || "",
          refund: prev?.refund || "",
          exchangerate: prev?.exchangerate || "",
          amount: prev?.amount || "",

          account: commonTransaction.account,
          type: commonTransaction.type,
          component: commonTransaction.component,
          description: commonTransaction.description || "", // important
          classifier: commonTransaction.classifier,
          mode: commonTransaction.mode,
          people: commonTransaction.people,
          state: { ACTUAL: "A", VIRTUAL: "V" }[commonTransaction.state] || "A",
          total: commonTransaction.total,
        };

        return currentTransaction;
      });
    }
  }, [commonTransaction]);

  const handleSelectChange = (e: DropdownChangeEvent) => {
    const { name, value } = e.target;
    setTransaction(
      (prev) =>
        ({
          ...prev,
          [name]: value,
          ...(name === "trndate" && { effectivedate: value }),
        } as ITransactionForm)
    );
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTransaction((prev) => {
      const currentTransaction = { ...prev };
      return {
        ...currentTransaction,
        [name]: value,
      } as ITransactionForm;
    });
  };

  const handleRecordTransaction = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (transaction) {
        const response: ApiResponse = await addTransaction(transaction);

        if (response.data) {
          toastNotify("Transaction Record added !");
        } else if (response.error) {
          toastNotify(response.error.data.errormessages[0], "error");
        }
      }
    } catch (error) {
      toastNotify("Transaction error", "error");
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <MainLoader />
        </div>
      )}
      <form
        className="transact-form"
        method="POST"
        onSubmit={handleRecordTransaction}
      >
        <h3>Add Transaction</h3>
        <div>
          <ul>
            <li>
              <SelectFormField
                label="Account"
                name="account"
                value={transaction?.account}
                options={accounts || []}
                optionLabel="account"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Component"
                name="component"
                value={transaction?.component}
                options={componentNames || []}
                optionLabel="component"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Contributor"
                name="people"
                value={transaction?.people}
                options={contributors || []}
                optionLabel="people"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <InputFormField
                label="Description"
                name="description"
                value={transaction?.description}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Quantity"
                name="quantity"
                value={transaction?.quantity}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="State"
                name="state"
                value={transaction?.state}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Amount"
                name="amount"
                value={transaction?.amount.toString()}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <label>Transaction Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={transaction?.trndate}
                  name="trndate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <SelectFormField
                label="Type"
                name="type"
                value={transaction?.type}
                options={transactTypes || []}
                optionLabel="component"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Transfer Mode"
                name="mode"
                value={transaction?.mode}
                options={transactModes || []}
                optionLabel="mode"
                onChange={handleSelectChange}
              />
            </li>
            <li>
              <SelectFormField
                label="Transaction Group"
                name="transactgroup"
                value={transaction?.transactgroup || ""}
                options={transactGroups || []}
                optionLabel="transactgroup"
                onChange={handleSelectChange}
                required={false}
              />
            </li>
            <li>
              <InputFormField
                label="Extra Charge"
                name="xtracharge"
                value={transaction?.xtracharge}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Extra Charge Desc"
                name="xtrachargedesc"
                value={transaction?.xtrachargedesc}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <InputFormField
                label="Refund"
                name="refund"
                value={transaction?.refund}
                onChange={handleTextChange}
              />
            </li>

            <li>
              <InputFormField
                label="Exchange Rate"
                name="exchangerate"
                value={transaction?.exchangerate}
                onChange={handleTextChange}
              />
            </li>
            <li>
              <label>Effective Date</label>
              <div className="card flex justify-content-center ">
                <Calendar
                  value={transaction?.effectivedate}
                  name="effectivedate"
                  onChange={handleSelectChange}
                  dateFormat="dd/mm/yy"
                  className="p-inputtext-sm"
                  showIcon
                />
              </div>
            </li>
          </ul>
        </div>
        <button className="transact-form-button" type="submit">
          Record Transaction
        </button>
      </form>
    </>
  );
};

export default TransactionForm;
